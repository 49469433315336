declare global {
	interface Window {
		flutter_inappwebview: any;
	}
}
import Image from 'next/image';
import type {NextPage} from 'next';
import googleLogin from '../images/login/googleLogin.png';
import Ellipse2 from '../images/login/Ellipse 2.png';
import Ellipse3 from '../images/login/Ellipse 3.png';
import Vector1 from '../images/login/Vector 1.png';
import Vector2 from '../images/login/Vector 2.png';
import Link from 'next/link';
import {strapi_end_point} from '../config';
import {useEffect, useState} from 'react';
import axios from 'axios';
import {useRouter} from 'next/router';
import {validateEmail} from '../utils/input-validation';
import {storeJwt, storeUser, RememberUser, checkLogin, getRememberUser, destroyRemember} from '../utils/handle-session';
import {TiInfo} from 'react-icons/ti';
import {useNavigate} from 'react-router-dom';
const crypto = require('crypto');
const Login: NextPage = () => {
	const router = useRouter();
	const [userEmail, setUserEmail] = useState<string>('');
	const [userPassword, setUserPassword] = useState<string>('');
	const [userEmailError, setUserEmailError] = useState<string>('');
	const [userPasswordError, setUserPasswordError] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [anchortag, setAnchorTag] = useState<boolean>(true);
	const [isLocalProvider, setIsLocalProvider] = useState<boolean>(router.query.provider === 'local' ? true : false);
	const [error, setError] = useState<any>(router.query.error != '' ? router.query.error : '');
	const [rememberme, SetRememberme] = useState<boolean>(false);
	const handleSignin = async () => {
		var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		var checker
		if (userEmail.match(validRegex)) {
			checker = await axios.get(`${strapi_end_point}/api/users?filters[email]=${userEmail}&filters[provider]='local'`);
		}else{
			checker = await axios.get(`${strapi_end_point}/api/users?filters[username]=${userEmail}`);
		}
		if (userEmail.length == 0) {
			return setUserEmailError('Email or Username is required');
		} else if (checker.data.length === 0) {
			setUserEmailError("The email address or username you entered isn't connected to an account. Create a new Gurucool account");
		} else if (checker.data[0].provider !== 'local') {
			return setUserEmailError('Please use google to sign in');
		} else {
			setUserEmailError('');
		}

		if (userPassword.length === 0) {
			return setUserPasswordError('Password is required');
		} else {
			setUserPasswordError('');
		}

		try {
			setLoading(true);
			const response = await axios.post(`${strapi_end_point}/api/auth/local`, {
				identifier: userEmail,
				password: userPassword,
			});
			storeJwt(response.data.jwt);
			storeUser(response.data.user);
			if (rememberme) {
				const algorithm = 'aes-256-cbc'; //Using AES encryption
				const key = crypto.randomBytes(32);
				const iv = crypto.randomBytes(16);
				// protected data
				const message = userPassword;
				// the cipher function
				let cipher = crypto.createCipheriv(algorithm, Buffer.from(key), iv);
				// output encoding
				let encrypted = cipher.update(message);
				encrypted = Buffer.concat([encrypted, cipher.final()]);
				const encryptedData = encrypted.toString('hex');
				RememberUser({email: userEmail, password: encryptedData, algorithm: algorithm, key: key, iv: iv, ivStr: iv.toString('hex')});
			} else {
				let rememberUser = getRememberUser();
				if (rememberUser) {
					destroyRemember();
				}
			}
			// window.close();
			if (router.query.last_url) {
				window.location.assign(router.query.last_url && router.query.last_url.toString());
			} else {
				window.location.assign('https://padhaai.gurucool.xyz/');
			}
		} catch (error) {
			setLoading(false);
			console.log(error);
			if (axios.isAxiosError(error)) {
				if (error.response?.data.error.message.toLowerCase().includes('email')) return setUserEmailError(error.response?.data.error.message);
				return setUserPasswordError(error.response?.data.error.message);
			}
		}
		setLoading(false);
	};
	useEffect(() => {
		if (typeof window !== 'undefined' && checkLogin()) {
			router.push(router.query.last_url ? router.query.last_url.toString() : 'https://padhaai.gurucool.xyz/');
		}
		const rememberUser = getRememberUser();
		if (rememberUser) {
			SetRememberme(!rememberme);
			let userData = JSON.parse(rememberUser);
			let iv = Buffer.from(userData.ivStr.toString('hex'), 'hex');
			let encryptedText = Buffer.from(userData.password, 'hex');
			let decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(userData.key), iv);
			let decrypted = decipher.update(encryptedText);
			decrypted = Buffer.concat([decrypted, decipher.final()]);
			setUserEmail(userData.email);
			setUserPassword(decrypted.toString());
		}
	}, [router.isReady]);
	return (
		<>
			<section className="pt-[30px] pb-[30px] h-screen flex justify-center items-center">
				<div className="innerDiv 2xl:w-[1280px] w-full">
					<div className="flex">
						<div className="hidden relative overflow-hidden lg:flex pl-16 pr-0 flex-col gap-4 items-start justify-center bg-[#202020] basis-[55%]">
							<div className="circleAnimation absolute top-32 left-24 ">
								<Image src={Ellipse3} alt=""></Image>
							</div>
							<div className="circleAnimation2 absolute bottom-32 right-28 ">
								<Image src={Ellipse2} alt=""></Image>
							</div>
							<div className="vectiorAnimation w-full z-0 absolute top-0 left-0">
								<Image src={Vector2} alt=""></Image>
							</div>
							<div className="vectiorAnimation2 w-full absolute bottom-0 left-0">
								<Image src={Vector1} alt=""></Image>
							</div>
							{/* <Image src={Vector1}></Image> */}
							<h1 className="font-semibold z-20 text-white text-4xl leading-snug">
								Ready to learn with <br /> Gurucool ?
							</h1>
							<p className="text-white">Login to your account to resume from where you left</p>
						</div>
						<div className="flex flex-col m-auto items-center justify-center max-w-[670px] w-full  sm:border-2 sm:border-lighter-grey lg:px-16 md:px-12 sm:py-8 border-l-0  lg:basis-1/2">
							<div className="flex justify-between items-center w-full mb-10">
								<h1 className="text-3xl font-medium">Login</h1>
								<select className='appearance-none text-[#7E7E7E] text-sm font-medium outline-none pr-[2rem!important] bg-white'>
									<option>English</option>
									<option>हिन्दी</option>
									<option>español</option>
									<option>اردو</option>
								</select>
								<div className="hidden sm:block">
									<Link href="https://padhaai.gurucool.xyz/">
										<a>
											<Image src="/favicon.png" objectFit="contain" width="30" height="30" alt="" />
										</a>
									</Link>
								</div>
							</div>
							<a
								id="GoogleSigninBtnWebsite"
								onClick={() => {
									try {
										const args = [
											{
												is_login: true,
											},
										];
										window.flutter_inappwebview.callHandler('GoogleAuth', ...args);
										// window.location.href = `${strapi_end_point}/api/connect/google`;
									} catch (err: any) {
										window.location.href = `${strapi_end_point}/api/connect/google`;
									}
								}}
								className="flex items-center cursor-pointer flex-row justify-center bg-white py-4 sm:px-10 px-4 rounded-full border-2 border-lighter-grey w-full">
								<Image src={googleLogin} className="inline-block" width="20" height="20" alt=""></Image>
								<h2 className="text-sm font-medium inline-block"> Sign in with Google</h2>
							</a>
							<a
								id="GoogleSigninBtnApp"
								style={{display: 'none'}}
								onClick={() => {
									try {
										const args = [
											{
												is_login: true,
											},
										];
										window.flutter_inappwebview.callHandler('GoogleAuth', ...args);
									} catch (err: any) {
										window.location.href = `${strapi_end_point}/api/connect/google`;
									}
								}}
								className="flex items-center flex-row justify-center bg-white py-4 sm:px-10 px-4 rounded-full border-2 border-lighter-grey w-full mx-auto">
								<Image src={googleLogin} className="inline-block" width="20" height="20" alt=""></Image>
								<h2 className="text-sm font-medium inline-block"> Sign in with Google</h2>
							</a>
							<span className="text-[#FF0000] mt-3 mx-3 text-sm">{isLocalProvider && <>Looks like you have signed up before using email/username and password, please use your email/username and password to login.</>}</span>
							<div className="flex items-center justify-center w-full mt-6 mb-6">
								<div className="bg-[#DEDEDE] h-[1px]  flex-1 "> </div>
								<p className="text-[#939393] px-4">OR</p>
								<div className="bg-[#DEDEDE] h-[1px]  flex-1"> </div>
							</div>

							<div className="flex mb-6 flex-col items-start justify-center w-full">
								<div className="mb-6 flex flex-col w-full">
									<label htmlFor="email" className="text-md font-semibold mb-4">
									  Email or username <span className="text-primary">*</span>
									</label>
									<input
										type="email"
										name="email"
										id="email"
										value={userEmail}
										onChange={(e) => setUserEmail(e.target.value)}
										placeholder="someone@example.com"
										className="bg-[#F5F5F5] w-full py-4 px-4 pl-6 rounded-full focus:ring-primary focus:outline-0 focus:ring-1 focus:border-transparent"
									/>
									<span className="text-[#FF0000] mt-2 ml-3 text-xs flex align-center">
										{userEmailError.length > 0 && (
											<>
												<TiInfo className="text-sm mr-1" />
												{userEmailError}
											</>
										)}
									</span>
								</div>
								<div className="mb-6 flex flex-col w-full">
									<label htmlFor="password" className="text-md font-semibold inline-block mb-4">
										Password <span className="text-primary ">*</span>
									</label>
									<input
										type="password"
										name="password"
										id="password"
										value={userPassword}
										onChange={(e) => setUserPassword(e.target.value)}
										placeholder="Your Secure Password"
										className="bg-[#F5F5F5] w-full py-4 px-4 pl-6 rounded-full focus:ring-primary focus:ring-1 focus:outline-0 focus:border-transparent"
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												handleSignin();
											}
										}}
									/>
									<span className="text-[#FF0000] mt-2 ml-3 text-xs flex align-center">
										{userPasswordError.length > 0 && (
											<>
												<TiInfo className="text-sm mr-1" />
												{userPasswordError}
											</>
										)}
									</span>
								</div>
								<div className="flex justify-between w-full mb-8">
									<div className="flex items-center">
										<input
											type="checkbox"
											checked={rememberme}
											name="remember"
											onChange={() => {
												SetRememberme(!rememberme);
											}}
											id="remember"
											className="checked:bg-primary focus:ring-white mr-2"
										/>
										<label htmlFor="remember" className="text-sm font-semibold ">
											Remember me
										</label>
									</div>
									<Link href="/forgot-password">
										<a className="text-primary text-sm font-semibold cursor-pointer">Forgot Password?</a>
									</Link>
								</div>
									<span className="text-[#FF0000] mt-2 ml-3 text-xs flex align-center">
										{error && error.length > 0 && (
											<>
												<TiInfo className="text-sm mr-1" />
												{error}
											</>
										)}
									</span>
								<button className="bg-primary text-black text-md font-semibold w-full text-center rounded-full py-4 flex justify-center items-center" onClick={handleSignin} disabled={loading}>
									{loading && <span className="spinner-loader mr-2"></span>}
									<span>Login</span>
								</button>
							</div>
							<div className="flex justify-between w-full ">
								<div className="flex items-center gap-2">
									<span className="text-sm font-semibold">Not registered? </span>
									<a
										onClick={() => {
											window.location.href = "/signup";
										}}
										className="text-primary text-sm font-semibold cursor-pointer">
										Sign up
									</a>
								</div>
								<Link href="https://padhaai.gurucool.xyz/privacypolicy">
									<a target="_blank" className="text-primary text-sm font-semibold">
										Privacy Policy
									</a>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			<style global jsx>{`
				footer {
					display: none !important;
				}
			`}</style>
		</>
	);
};

export default Login;
function GoogleAuth() {
	throw new Error('Function not implemented.');
}
